<template>
  <div>
    <Header />
    <Mobileheader />

    <div class="aboutus_main" data-aos="fade-up" data-aos-duration="3000">
      <div class="small_break"></div>
      <h1 class="heading">ABOUT SINDHIZ GROUP</h1>
      <div class="small_break"></div>
      <h3 class="sub_heading">Our Experience</h3>
      <div class="small_break"></div>

      <p class="about_para">
        With decades in retail and hospitality, the RMI team stands ready take
        the stress and daily frustrations out of operating a retail business.
        Leverage our<br />
        experience, our insights, our industry connections, and our ability to
        consolidate processes, programs and opportunities, we'll work with you
        and for you to <br />
        maximise your businesses success.
      </p>
      <div class="small_break"></div>

      <b-container>
        <b-row>
          <b-col md="6" lg="6">
            <h3 class="sub_heading">Our Approach</h3>
            <div class="small_break"></div>
            <p class="about_para">
              Our service includes a comprehensive consultation to identify your
              needs. We offer a full-service approach, and will customise our
              offer to your specific needs if you don't need the full package.
              We can cost effectively bring our years of experience, our systems
              and processes to provide consistent performance to your business.
              No two businesses are the same. We recognize the power of
              individual locations, but bring the power of our client base by
              leveraging it with vendors and larger scale opportunities.
            </p>
          </b-col>
          <b-col md="6" lg="6">
            <h3 class="sub_heading">Why Us?</h3>
            <div class="small_break"></div>
            <p class="about_para">
              Business mentors are key. We want to give each of you the time and
              guidance you deserve. The RMI team has experience in large
              nationwide retailers, and in individual ownership locations. We
              recognize the advantages of both, and use this to help your
              business. Our services are transparent as are our costs. Isn't it
              time you focus on what you want to focus on, and let the worry of
              the day-to-day fall to someone else? That's where we come in.
            </p>
          </b-col>
        </b-row>
      </b-container>

      <div class="mediumbreak"></div>
    </div>
    <div class="mediumbreak"></div>
    <b-container class="our_team" data-aos="fade-up" data-aos-duration="3000">
      <h1 class="ourteam_heading">Meet Our Team</h1>

      <p class="para" >
        Our team is your team. When your mission is to be better, faster and
        smarter, you<br />
        need the best people driving your vision forward. You need people who
        can create <br />
        focused marketing strategies that align with business goals, who can
        infuse their<br />
        creativity into groundbreaking campaigns, and who can analyze data to
        optimize<br />
        every tactic along the way. You need Walker Sands. Get to know your team
        below.
      </p>
    </b-container>

    <div class="mediumbreak"></div>
    <div class="our_team_card" data-aos="fade-up" data-aos-duration="3000">
      <b-container>
        <b-row>
          <b-col md="3" lg="3" sm="3" class="mediumbreak">
            <div class="team_img">
              <b-img
                src="@/assets/images/our_team/beautiful-young-female-company-ceo-successful-business-lady-work-119168602.jpg"
                class="full-width"
              >
              </b-img>
              <h1 class="heading">Name</h1>
              <h5 class="sub_heading">CEO</h5>
            </div>
          </b-col>

          <b-col md="3" lg="3" sm="3" class="mediumbreak">
            <div class="team_img">
              <b-img
                src="@/assets/images/our_team/depositphotos_7800131-stock-photo-business-man.jpg"
                class="full-width"
              >
              </b-img>
              <h1 class="heading">Name</h1>
              <h5 class="sub_heading">CEO</h5>
            </div></b-col
          >
          <b-col md="3" lg="3" sm="3" class="mediumbreak"
            ><div class="team_img">
              <b-img
                src="@/assets/images/our_team/istockphoto-1193290387-612x612.jpg"
                class="full-width"
              >
              </b-img>
              <h1 class="heading">Name</h1>
              <h5 class="sub_heading">CEO</h5>
            </div>
          </b-col>
          <b-col md="3" lg="3" sm="3" class="mediumbreak"
            ><div class="team_img">
              <b-img
                src="@/assets/images/our_team/istockphoto-1091058068-612x612.jpg"
                class="full-width"
              >
              </b-img>
              <h1 class="heading">Name</h1>
              <h5 class="sub_heading">CEO</h5>
            </div>
          </b-col>
        </b-row>
        <div class="mediumbreak"></div>
      </b-container>
    </div>
    <div class="largebreak"></div>

    <Footer />
  </div>
</template>

<script>
import { BContainer, BRow, BCol, BImg } from "bootstrap-vue";
import Header from "./components/header.vue";
import AOS from "aos";
import "aos/dist/aos.css";
import Footer from "./components/Footer.vue";
import Mobileheader from "./components/mobileheader.vue";

export default {
  components: {
    Footer,
    BContainer,
    // BButton,
    // BImg,
    BRow,
    BImg,
    BCol,
    // BCard,
    // BCardText,
    // BLink,
    Header,
    Mobileheader,
  },
  created() {
    AOS.init();
  },
};
</script>
